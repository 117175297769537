@charset "UTF-8";
@import (reference) "_includes/_vars.less";
@import (reference) "_includes/_mixins.less";
@import "_includes/slick.less";
@import "ground.less";
@import "aos.css";

/*---------------------------------------*\
   Header
\*---------------------------------------*/

.header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  color: @text;
  z-index: 100;
  background: @secondary;
  .transition-default();
  &__logo {
    line-height: 1;
    padding: @offset 0;
    max-width: 105px;
    float: left;
    text-align: center;
    transform: scale(1);
    a {
      &:extend(.clear);
    }
    img {
      &:extend(.img_responsive all);
    }
  }
  &__menu {
    display: none;
    margin-bottom: @offset;
    .fs_px(12);
    font-weight: 400;
    text-align: center;
    &:extend(.clear);
    ul {
      li {
        display: block !important;
        width: 100%;
        opacity: 0;
        a {
          width: 100%;
          padding: @offset @offset @offset 0;
          opacity: 1;
          color: @white;

          .hover({
            color: @primary;
          });
        }
      }
    }
    body.screen_sm &, body.screen_md &, body.screen_lg & {
      width: auto;
      display: block !important;
      position: absolute;
      right: 0;
      top: 0;
      ul {
        li {
          opacity: 1 !important;
          display: inline-block !important;
          width: auto;
          a {
            padding: @offset @offset-md;
          }
        }
      }
    }
  }

  &__toggle {
    cursor: pointer;
    width: 38px;
    height: 35px;
    margin-right: 5px;
    position: absolute;
    top: .4rem;
    right: @offset;
    &:extend(.not_selectable all);
    .transition-default();
    div {
      height: 3px;
      margin: 9px auto;
      width: 100%;
      background: @white;
      .transition-default();
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.on {
      div {
        background: @primary;
      }
      .one {
        transform: rotate(135deg);
        margin-top: 20px;
      }
      .two {
        opacity: 0;
      }
      .three {
        transform: rotate(-135deg);
        margin-top: -24px;
      }
    }
    body.screen_sm &, body.screen_md &, body.screen_lg & {
      display: none !important;
    }
  }
  .menu_active & {
    .bga(@secondary, 0.9);
  }
  .menu_active.screen_sm & {
    background: transparent !important;
  }
}

/*---------------------------------------*\
   sections
\*---------------------------------------*/
.separate {
  height: 7px;
  border-radius: 3px;
  background-color: @primary;
}
.num {
  .fs(148);
  color: @grayy;
  opacity: .3;
  &.wh {
    color: @white;
    display: block;
  }
}
.section_banner {
  &:extend(.bg_set all);
  height: 100%;
  display: flex;
  align-items: center;
  max-height: 580px;
  @media(max-width: @screen_tablet) {
    background-position: 60% center;
  }
  &__text {
    position: relative;
    z-index: 2;
    max-width: 420px;
    padding: 1rem;
    span {
      .fs(28, 20);
      font-weight: 400;
      color: @white;
    }
    div.separate {
      width: 255px;
      margin: 1.5rem 0;
    }
  }
}

.section_about {
  padding: @offset-xl 0;
  @media(max-width: @screen_tablet_max) {
    padding: @offset-md 0;
  }
  .overlay {
    width: 70%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: @grayy;
    z-index: -1;
    @media(max-width: @screen_desktop) {
      opacity: 0;
    }
  }
  &__text {
    text-align: center;
    max-width: 960px;
    margin: auto;
    .fs(16);
  }
  p {
    .fs(18)
  }
  header {
    @media(max-width: @screen_desktop) {
      text-align: center;
      padding: 4rem;
      margin: 4rem auto 0;
      position: relative;
    }
    .num {
      @media(max-width: @screen_desktop) {
        .fs(245);
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        float: none;
        top: -4rem;
        z-index: -1;
      }
    }
      .mobilehiden {
        display: block;
          @media(max-width: @screen_desktop) {
            display: none;
          }
        }
      .desktophiden {
        display: none;
        @media(max-width: @screen_desktop) {
          display: block;
        }
      }
      .separate {
        @media(max-width: @screen_desktop) {
        max-width: 185px;
        margin: auto;
          .mobilehiden {
            display: none!important;
          }
        }
      }
    }
  }
.section_services {
  margin-top: 4rem;
  header {
    padding: 4rem;
    margin: 4rem auto 0;
    position: relative;
    .separate {
      max-width: 185px;
      margin: auto;
    }
    .num {
      .fs(245);
      &:extend(.absolute_align all);
      top:-4rem;
      z-index: -1;
    }
    h2 {
      &:extend(.title1 all);
    }
  }
  &__text {
    .fs(11);
    max-width: 745px;
    margin: auto;
  }
  &__cards {
    .card {
      box-shadow: 21px 25px 45px 21px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      background-color: @white;
      margin: 4rem 1rem;
      &__in {
        h2 {
          .fs(24);
          color: @secondary;
          text-align: center;
        }
        padding: @offset-md;
        p,li {
          .fs(13);
        }
      }
    }
  }
}
.section_career {
  background-color: @primary;
  max-height: 750px;
  &__text {
    padding: 12rem 0;
    float: right;
    color: #000;
    @media(max-height: 800px) {
      padding: 4rem 0;
    }
    header {
      position: relative;
      left: 6rem;
      z-index: 4;
      .separate {
        background-color: @secondary;
        max-width: 210px;
      }
      .num {
        color: @white;
      }
      h2 {
        &:extend(.title1 all);
      }
      }
    @media(max-width: @screen_desktop) {
      float: none;
      padding: 4rem 6rem;
      header {
        left:0;
      }
      .btn-white {
        text-align: center;
      }
    }
    @media(max-width: @screen_mobile_max) {
      padding: 4rem 0;
    }
    h3 {
      .fs(30);
      color: @white;
    }

  }
  &__cta {
    @media (max-width: @screen_desktop) {
      text-align: center;
    }
  }
  &__imgholder {
    max-width: 450px;
    margin: auto;
    position: relative;
    z-index: 2;
    img {
      &:extend(.img_responsive all);
      display: none;
      transform: scale(1.1);
    }

    .img_mobile {
      body.screen_sm &, body.screen_xs &, body.screen_xxs & {
        display: block !important;
      }
    }
    .img_desktop {
      body.screen_md &, body.screen_lg & {
        display: block !important;
      }
    }
  }
}


.section_contact {
  padding: @offset-xxl 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &__wrap {
    position: relative;
    box-shadow: 21px 25px 59px 21px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #ffffff;
    padding: @offset-md @offset-lg;
    @media(max-width: @screen_mobile) {
      padding: @offset-md;
      margin-bottom: @offset-lg;
    }
  }
  &__info {
    max-width: 260px;
    float: none;
    @media(max-width: @screen_desktop) {
      margin: auto;
      margin-top: @offset-md;
    }
    @media(min-width: @screen_desktop) {
      float: right;
    }
    .num {
      margin: @offset-md auto;
    }
    address {
      margin-bottom: @offset ;
    }
    address, a {
      .fs(13);
      font-style: normal;
    }
  }
}

.section_form {
  padding: @offset-xl @offset;
  &__wrap {
  }
}

.ml-2 {
    margin-left: 2rem;
  @media(max-width: @screen_desktop){
    margin-left: 0;
  }
}

.pl-1{
  padding-left: 1rem;
}

  ul.regular-ul {
    .ul_reset();
    li {
      .fs(16);
      padding-left: @offset-md;
      margin-bottom: @offset-sm;
      position: relative;
      font-weight: 300;
      &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: @secondary;
        position: absolute;
        top: 6 / @bs * 1rem;
        left: 0;
      }
    }
    &.c {
      li {
        &:before {
          background-color: @primary;
        }
      }
    }
  }
/*---------------------------------------*\
   Footer
\*---------------------------------------*/
.footer {
  clear: both;
  .fs(10);
  font-weight: 400;
  text-align: center;
  color: @white;
  background-color: @secondary;

  .container {
    padding: 2rem 1rem;
  }

  &__logo {
    text-align:center;
    padding: @offset-md 0 @offset;
    img {
      &:extend(.img_responsive all);
      max-width: 144px;
    }
    @media(max-width: @screen_mobile) {
      width: 100%;
      float: none;
      text-align: center;
    }
  }
  &__text {
    width: 100%;
    float: none;
    text-align: center;
    margin: @offset-md 0;
  }
}
.mb-8 {
  margin-bottom: 8rem;
}

/*---------------------------------------*\
   Font sizes Media Query
\*---------------------------------------*/
@font_size_mobile: 13;
@font_size_tablet: 14;
@font_size_desktop: 15;
@font_size_large: 16;

.p0 {
  @media (max-width: @screen_desktop) {
    padding: @offset 0 !important;
  }
}
.p4 {
  padding: 0 4rem;
}
.headt {
  color: @primary;
  padding: @offset-xs;
  padding-left: 0;
}

.slick-slide, .slick-list {
  height: unset;
}
.slick-slider {
  bottom:-6rem;
  z-index: 3;
}

/*---------------------------------------*\
   App Media Query
\*---------------------------------------*/
 @media (min-width: @screen_mobile) {
  html {
    font-size:unit(@font_size_mobile,px);
  }

}
@media (min-width: @screen_tablet) {
  html {
    font-size:unit(@font_size_tablet,px);
  }
}
@media (min-width: @screen_desktop) {
  html {
    font-size:unit(@font_size_desktop,px);
  }
}
@media (min-width: @screen_large) {
  html {
    font-size:unit(@font_size_large,px);
  }
}

